import Theme from "theme";
import FormList from "./formList";
import { useState, useEffect } from "react";

export default (opt, getElement) => {
  // retornar caixa de input dinamico com botao de adicionar e acresccentar box de input
  // const [inputs, setInputs] = useState([]);
  const Form = FormList(opt);
  return {
    component: "container",
    style: {
      flex: 1,
      width: "100%",
      height: "100%",
      overflowY: "none",
    },
    children: [
      {
        // title
        component: "text",
        style: {
          ...Theme.text.bigTitle,
          flex: 1,
          width: "100%",
          marginTop: 10,
          paddingLeft: 30,
        },
        text: opt.label,
      },
      {
        // input
        component: "container",
        style: {
          ...Theme.box.default,
          ...opt.style?.input,
          width: "100%",
          flex: 1,
        },
        children: [
          {
            component: "container",
            style: {
              maxHeight: 600,
              flex: 1,
              paddding: 30,
              width: "100%",
              overflow: "auto",
              ...opt.style.main,
            },
            // enviar lista de cobntrole de elementos e tbm conseguir obter dados ataves de um state...
            children: [
              {
                render: Form,
              },
            ],
          },
        ],
      },
    ],
  };
};
