//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//import global
import Button from "components/web/button";
import ListView from "components/web/view/list";

//images
// import OctalLogo from "./midia/images/8D/logo.svg";

const PageID = "ClientsList";

export default ({ ContentShow, GoContent }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  return {
    render: (
      <Fade>
        {Render({
          component: "container",
          style: {
            display: ContentShow === PageID ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            width: "100%",
          },
          children: [
            ListView({
              id: "user-list",
              title: "Clientes OctalDev",
              style: {
                cel: (props) => {
                  switch (props.col) {
                    default:
                      return {
                        fontWeight: "normal",
                      };
                  }
                },
                row: {
                  width: "100%",
                  fontWeight: "normal",
                  display: "flex",
                  fontSize: "1vw",
                  fontFamily: "Poppins",
                  minHeight: 42,
                  maxHeight: 42,
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 8,
                },
                col: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  borderRight: "none",
                  fontWeight: "bold",
                  padding: 8,
                  minHeight: 32,
                  maxHeight: 32,
                },
              },
              col: {
                name: {
                  title: "Nome",
                  style: {
                    flex: 1,
                  },
                },
                project: {
                  title: "Projeto",
                  style: {
                    flex: 1,
                  },
                },
                email: {
                  title: "E-mail",
                  style: {
                    flex: 1,
                  },
                },
                nicho: {
                  title: "Nicho",
                  style: {
                    flex: 1,
                  },
                },
                details: {
                  title: "Ver detalhes",
                  style: {
                    flex: 1,
                  },
                },
              },
              item: api.people.listAll,
            }),
            {
              component: "container",
              style: {
                width: "100%",
                height: "14vh",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                paddingRight: "4%",
              },
              children: [
                Button({
                  style: {
                    button: {
                      width: "auto",
                      height: 42,
                      backgroundColor: "#212121",
                      border: "none",
                      borderRadius: 8,
                      padding: 8,
                    },
                    label: {
                      fontSize: "0.8vw",
                      fontFamily: "Poppins",
                      color: "white",
                    },
                  },
                  label: "Novo cliente",
                  onPress: async () => {
                    GoContent("ClientsAdd");
                  },
                }),
              ],
            },
          ],
        })}
      </Fade>
    ),
  };
};
