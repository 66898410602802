import React, { useEffect, useState } from "react";

const TypingAnimation = ({ phrases, style }) => {
  const [text, setText] = useState("");
  const [phase, setPhase] = useState("typing");

  useEffect(() => {
    let timeout;
    if (phase === "typing") {
      const currentPhrase = phrases[0];
      if (text.length < currentPhrase.length) {
        timeout = setTimeout(() => {
          setText(currentPhrase.slice(0, text.length + 1));
        }, 70);
      } else {
        timeout = setTimeout(() => {
          setPhase("erasing");
        }, 900);
      }
    } else if (phase === "erasing") {
      if (text.length > 0) {
        timeout = setTimeout(() => {
          setText(text.slice(0, text.length - 1));
        }, 50);
      } else {
        timeout = setTimeout(() => {
          setPhase("final");
        }, 500);
      }
    } else if (phase === "final") {
      const finalPhrase = phrases[1];
      if (text.length < finalPhrase.length) {
        timeout = setTimeout(() => {
          setText(finalPhrase.slice(0, text.length + 1));
        }, 50);
      }
    }

    return () => clearTimeout(timeout);
  }, [text, phase]);

  return (
    <div
      style={{
        fontSize: "3.66vw",
        fontFamily: "Poppins",
        marginLeft: 12,
        color: "#134B82",
        whiteSpace: "nowrap",
        ...style,
      }}
    >
      {text}
    </div>
  );
};

export default TypingAnimation;
