import { Render } from "@8_dev/sjson-web";
import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { ShowPage } from "context/showPage";
// import Theme from "theme";

export default (props) => {
  const { GoBack } = ShowPage();

  useEffect(async () => {}, []);

  // passa props o search caso tenha campo de busca na pagina ou cateudo da pagina..
  // colocar um algo para omitir os dados filtrados

  return {
    render: (
      <div style={{}}>
        <Fade top>
          {Render({
            component: "container",
            style: {
              minWidth: "80vw",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            },
            children: [
              // Icon Title + Count
              {
                component: "container",
                style: {
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  minWidth: "100%",
                  height: "100%",
                },
                children: [
                  // Icon + text
                  // {
                  //   component: "container",
                  //   style: {
                  //     display: "flex",
                  //     flexDirection: "row",
                  //     backgroundColor: "rgba(220,230,250,0.4)",
                  //     borderRadius: "8px",
                  //     padding: "1%",
                  //     alignItems: "center",
                  //     justifyContent: "center",
                  //     cursor: "pointer",
                  //   },
                  //   onClick: () => {
                  //     GoBack();
                  //   },
                  //   children: [
                  //     {
                  //       component: "text",
                  //       // row back carecter
                  //       text: " <-- ",
                  //       style: {
                  //         marginRight: 8,
                  //       },
                  //     },
                  //     {
                  //       component: "text",
                  //       style: {
                  //         display: "flex",
                  //         alignItems: "center",
                  //         justifyContent: "center",
                  //         marginTop: 2,
                  //         fontSize: "1vw",
                  //         fontFamily: "Poppins",
                  //       },
                  //       text: " voltar ",
                  //     },
                  //   ],
                  // },
                  {
                    component: "container",
                    style: {
                      width: ".2vw",
                      height: "6vh",
                      backgroundColor: "#80A7CD",
                      // marginLeft: "1%",
                      // marginRight: "1%",
                      borderRadius: "16px",
                    },
                  },
                  {
                    component: "text",
                    text: props?.title || "  ",
                    style: {
                      marginLeft: "1%",
                      fontFamily: "Poppins",
                      fontSize: 24,
                      flex: 1,
                      width: "100%",
                    },
                  },
                ],
              },
            ],
          })}
        </Fade>
      </div>
    ),
  };
};
