//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//import global
import Input from "components/web/input";
import Button from "components/web/button";
// import NavBar from "components/web/navbar";

//images
import LogoOctal from "assets/images/octal/logo/logo.svg";

import FormLogin from "./login";
import RecoveryPassword from "pages/access/recovery";
import EmailSent from "pages/access/recovery/emailSent";

export default (props) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const [viewForm, setViewForm] = useState("LoginForm");

  const contentParam = {
    viewForm,
    setViewForm,
  };

  const acessForm = [
    FormLogin(contentParam),
    RecoveryPassword(contentParam),
    EmailSent(contentParam),
  ];

  useEffect(async () => {}, []);

  return {
    render: (
      <div
        style={{
          display: !Data.user.status ? "flex" : "none",
        }}
      >
        <Fade>
          {Render({
            component: "container",
            style: {
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              minHeight: "100vh",
            },
            children: [
              //faixa roxa
              {
                component: "container",
                style: {
                  width: "100%",
                  minHeight: "100%",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: 18,
                  background: "linear-gradient(to left, #3B405C 55%, #7D88C2)",
                  clipPath: "polygon(0 0, 100% 0, 100% 28%, 0 54%)",
                },
                children: [
                  {
                    component: "image",
                    src: LogoOctal,
                    style: {
                      width: 64,
                      height: 64,
                    },
                  },
                ],
              },
              //form Login
              {
                component: "container",
                style: {
                  display: "flex",
                  backgroundColor: "white",
                  alignItems: "center",
                  flexDirection: "center",
                  position: "fixed",
                  maxWidth: "32vw",
                  minWidth: "32vw",
                  minHeight: "64vh",
                  borderRadius: 2,
                  padding: 8,
                  boxShadow: "4px 24px 18px rgba(0, 0, 0, 0.2)",
                },
                children: acessForm,
              },
            ],
          })}
        </Fade>
      </div>
    ),
  };
};
