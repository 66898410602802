import Theme from "theme";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import getElement from "components/web/view/getElement";

export default (props) => {
  // console.log(props.item);
  // if (props.item?.onClick) delete props.item.onClick;

  return {
    component: "container",
    style: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    children: [getElement(props.item)],
  };
};
