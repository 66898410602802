import Theme from "theme";
import { Render } from "@8_dev/sjson-web";
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import UploadIcon from "assets/images/upload.png";
import Icon from "components/web/icon";
import { ShowPage } from "context/showPage";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default (param) => {
  const { PageInfo, GoPage, GoBack } = ShowPage();
  const [fileView, setFileView] = useState(false);
  const [fileInfo, setFileInfo] = useState({});

  useEffect(() => {
    if (fileInfo.content?.length > 0) {
      setFileView(false);
      setFileInfo({});
    }
  }, [PageInfo]);

  const onLoad = async (data) => {
    if (data?.length && data[0].name.split(".")[1] === "xlsx") {
      // Download data
      const reader = new FileReader();
      reader.onload = async (e) => {
        // const data = e.target.result;
        if (param.onChange) param.onChange(e.target.result);

        // const workbook = XLSX.read(data, { type: "array" });
        // const resultKeys = Object.keys(workbook.Sheets);
        // console.log(resultKeys);
      };

      reader.readAsArrayBuffer(data[0]);
      // setInputFileLabel(
      //   " 🆗 [ " + e.target.files[0].name.split(".")[0] + " ] carregado! "
      // );
    } else {
      const reader = new FileReader();

      const file = Array.isArray(data) ? data.pop() : data.target.files[0];
      if (!file) return;

      reader.onloadend = function (e) {
        file.content = reader.result;
        console.log(file);
        setFileView(true);
        setFileInfo(file);
        e.preventDefault();
        if (param.onChange) param.onChange(reader.result);
      };
      reader.readAsDataURL(file);
    }
    // else setInputFileLabel(" 😕 Arquivo inválido, seleciona outro.");
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (e) => {
      onLoad(e);
    },
  });

  const [inpt, setInpt] = useState({
    value: "",
    focus: false,
    error: false,
    ...param,
  });

  return {
    component: "container",
    style: {
      flex: 1,
      maxWidth: "100%",
      minHeight: 60,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      padding: ".5vh",
    },
    // onClick: () => { setInpt({...inpt, focus: true}) },
    children: [
      {
        component: "text",
        style: {
          display: param.title ? "flex" : "none",
          marginBottom: "4%",
          flex: 1,
          width: "100%",
          fontWeight: "bold",
          fontSize: "1vw",
          fontFamily: "Poppins",
          color: Theme.colors.secondary,
        },
        text: param.title || "",
      },

      {
        component: "container",
        style: {
          display: "flex",
          alignItems: "center",
          flexDirection: "center",
          flex: 1,
          width: "100%",
        },
        children: [
          // upload
          {
            render: (
              <div
                {...getRootProps()}
                style={{
                  width: "100%",
                  background: "#EFF0F7",
                  minHeight: 32,
                  maxHeight: 32,
                  maxWidth: "100%",
                  minWidth: "100%",
                  borderRadius: 8,
                  borderWidth: 2,
                  borderStyle: "dashed",
                  display: "flex",
                  flexDirection: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: 4,
                  ...param.style.box,
                }}
              >
                {/* // mostar preview conforme tipo de arquivo */}
                {param.icon?.length > 0 ? (
                  Render(
                    Icon({
                      src: param.icon || UploadIcon,
                      size: 16,
                    })
                  )
                ) : (
                  <></>
                )}
                <input
                  {...getInputProps()}
                  id="upload-file"
                  type="file"
                  multiple={false}
                  style={{ display: "none" }}
                  onChange={onLoad}
                />
                <p
                  style={{
                    color: Theme.colors.darkBlue,
                    fontSize: "1vw",
                    fontFamily: "Poppins",
                    marginLeft: "auto",
                    marginRight: "auto",
                    ...param.style.label,
                  }}
                >
                  {inpt.value?.length === 0
                    ? param.label
                    : "Clique para fazer upload"}
                </p>
              </div>
            ),
          },
          // icon
          {
            component: "container",
            style: {
              display: fileInfo.content?.length > 0 ? "flex" : "none",
              padding: 4,
            },
            children: [
              // image
              {
                component: "container",
                style: {
                  display: "flex",
                  gap: 2,
                },
                children: [
                  Icon({
                    src: param?.value || fileInfo.content,
                    size: 48,
                    style: {
                      image: {
                        borderRadius: 4,
                      },
                    },
                  }),
                  // closeIcon
                  {
                    component: "text",
                    style: {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "flex-end",
                      marginLeft: "auto",
                      cursor: "pointer",
                      padding: 4,
                      borderRadius: 4,
                      height: 8,
                      width: 8,
                      backgroundColor: Theme.colors.gray.background,
                      fontFamily: Theme.fontFamilies.primary,
                    },
                    text: "x",
                    onClick: () => {
                      setFileView(false);
                      setFileInfo({});
                      if (param.onChange) param.onChange("");
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
};
