import { Render } from "@8_dev/sjson-web";
import { ShowPage } from "context/showPage";
import api from "service";
import Table from "components/web/table";
import Fade from "react-reveal/Fade";
import ContentTopBar from "components/web/ContentTopBar";
import { useEffect } from "react";

export default (props) => {
  const { PageInfo, update } = ShowPage();

  const clientTableList = Table({
    col: props.col || {
      id: {
        title: "ID",
        style: { flex: 0.2 },
      },
    },
    filter: props?.filter,
    item: props.item,
    style: {
      box: {
        flex: 1,
        padding: 0,
        margin: 0,
        ...props?.style?.box,
      },
      cel: props?.style?.cel,
      col: {
        flex: 1,
        borderRight: `2px solid rgba(100,100,100, 0.2)`,
        ...props?.style?.col,
        marginBottom: 8,
        // backgroundColor: "gray",
        // borderRadius: 2,
      },
      filterBox: props?.style?.filterBox,

      row: {
        // borderRight: `2px solid rgba(100,100,100, 0.2)`,
        borderTop: `1px solid rgba(100,100,100,0.2)`,
        padding: 0,
        margin: 0,
        ...props?.style?.row,
      },

      rowIndex: { ...props.style?.rowIndex },
    },
  });

  useEffect(async () => {
    if (PageInfo.show === props.id) clientTableList.update(props.item);
  }, [PageInfo.show]);

  useEffect(async () => {
    if (update?.includes(props.id)) clientTableList.update(props.item);
  }, [update]);

  useEffect(async () => {
    // verificar se essa pagina esta no historico ou na pagina que esta sendo exibida...
    // atualiza somente conteudo especifico,
    // salvar na page as configs de filtro
    // salvar filtros no usuario e tras depois no login
    clientTableList.update(props.item);
  }, [props.item]);

  useEffect(async () => {
    if (props?.style?.row) clientTableList.rowStyle(props.style.row);
  }, [props.style?.row]);

  return {
    render: (
      <div
        style={{
          overflowY: "hidden",
          width: "100%",
          maxWidth: "100%",
          height: "94%",
          maxHeight: "94%",
          zIndex: 2,
        }}
      >
        <Fade top cascade>
          {Render({
            component: "container",
            style: {
              width: "100%",
              height: "100%",
              padding: "1%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            },
            children: [
              {
                component: "container",
                style: {
                  width: "100%",
                  marginBottom: 8,
                  borderRadius: 4,
                  display: props.topBar === false ? "none" : "flex",
                },
                children: [
                  ContentTopBar({
                    title: props.title,
                    search: [],
                    action: [],
                  }),
                ],
              },
              // },
              {
                component: "container",
                style: {
                  width: "100%",
                  borderRadius: 4,
                  padding: 8,
                  maxHeight: "100%",
                  flex: 1,
                },
                children: [clientTableList.component],
              },
              // table usar do RH
            ],
          })}
        </Fade>
      </div>
    ),
  };
};
