// import Theme from "../../../util/theme";
import React, { useState, useEffect } from "react";
import Logout from "assets/images/logout.png";
import LogoOctal from "assets/images/octal/logo/logo.svg";
import IconGoRight from "assets/images/menu/goRight.svg";
import { ShowPage } from "context/showPage";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import Icon from "components/web/icon";
import { ShowPopup } from "context/popup";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default (props) => {
  const { GoInitPage, GoPage, GoBack } = ShowPage();

  const { PopupShow, PopupClose } = ShowPopup();

  const [btnSelected, setBtnSelected] = useState(["Home"]);

  const [menuOpen, setMenuOpen] = useState(false);

  // const icon = (opt) => {
  //   return {
  //     component: "container",
  //     style: {
  //       // height: "2vh",
  //       flex: 1,
  //       minWidth: "100%",
  //       aspectRatio: 1,
  //       display: "flex",
  //       flexDirection: "column",
  //       alignItems: "center",
  //       justifyContent: "center",
  //       cursor: "pointer",
  //       ...props?.iconBox?.style,
  //     },
  //     onClick: () => {},
  //     children: [
  //       // image
  //       {
  //         render: (
  //           <img
  //             style={{
  //               width: "32%",
  //               height: "32%",
  //               aspectRatio: "1/1",
  //             }}
  //             src={opt.image || Home}
  //           />
  //         ),
  //       },
  //       // text
  //       {
  //         component: "text",
  //         text: opt.title || "",
  //         style: {
  //           fontSize: "0.92vw",
  //           maxWidth: "90%",
  //           textAlign: "center",
  //         },
  //       },
  //     ],
  //   };
  // };

  const handleIconClick = () => {
    setMenuOpen((prevState) => !prevState);
  };
  return {
    component: "container",
    style: {
      minWidth: menuOpen === true ? "8.8vw" : "4vw",
      maxHeight: "94%",
      height: "94%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      borderTopRightRadius: "18px",
      borderBottomRightRadius: "18px",
      boxShadow: "1px 1px 8px #bfbfbf",
      transition: "min-width 0.5s ease, transform 0.5s ease",
      paddingTop: 8,
      gap: 8,
      ...props?.style?.boxMain,
    },
    children: [
      //logo + iconGo
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: "6vh",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
          padding: 4,
          // paddingLeft: menuOpen === true ? 8 : 4,
        },
        children: [
          //icon
          {
            render: (
              <Fade>
                {Render({
                  component: "image",
                  style: {
                    height: "4vh",
                    display: "flex",
                    alignItems: "center",
                    transition: "min-width 0.5s ease, transform 0.5s ease",
                  },
                  src: menuOpen === true ? props?.logoOpenNav : LogoOctal || "",
                })}
              </Fade>
            ),
          },

          //Icon (goRight >> / <<goLeft)
          {
            component: "container",
            style: {
              height: 22,
              aspectRatio: "1/1",
              display: props?.iconOpenNav === undefined ? "none" : "flex",
              transition: "transform 0.3s ease-in-out",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
            },
            children: [
              Icon({
                size: "100%",
                src: props?.iconOpenNav,
                style: {
                  iconBox: {
                    display: props?.iconOpenNav === undefined ? "none" : "flex",
                  },
                  image: {
                    right: 0,
                    transform:
                      menuOpen === true ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "transform 0.3s ease-in-out",
                  },
                },
                onPress: () => {
                  handleIconClick();
                },
              }),
            ],
          },
        ],
      },

      //menu + logout
      {
        component: "container",
        style: {
          maxHeight: "90%",
          height: "90%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 4,
        },
        children: [
          //menu
          {
            component: "container",
            style: {
              flex: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 4,
              overflowY: "auto",
              overflowX: "hidden",
              ...props?.style?.menu,
            },
            // passar como parametro os botoes.
            children: props?.item?.map((menu) => {
              return {
                //Icon
                component: "container",
                style: {
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: menuOpen === true ? "0.66vw" : 6,
                  borderRadius: 2,
                  padding: 6,
                  flexDirection: "column",
                  cursor: "pointer",
                },
                children: [
                  Icon({
                    size: "62%" || menu?.image?.size,
                    title: menu?.title,
                    src: menu.image?.src,
                    style: {
                      iconBox: {
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        borderRadius: 4,
                        height: "4vh",
                        width: menuOpen === true ? "100%" : "82%",
                        boxShadow: btnSelected.includes(menu.page)
                          ? "1px 1px 8px #80A7CD"
                          : "",
                        justifyContent:
                          menuOpen === true ? "flex-start" : "center",
                        transition: "background-color 0.5s ease-in-out",
                        background: btnSelected.includes(menu.page)
                          ? "#BFD3E6"
                          : "transparent",
                        ...menu?.style?.iconBox,
                      },
                      title: {
                        fontSize: "1.42vh",
                        display: menuOpen === true ? "flex" : "none",
                        color: btnSelected.includes(menu.page)
                          ? "rgba(17, 24, 39, 1)"
                          : "gray",
                        fontWeight: "400",
                        textAlign: "flex-start",
                        maxWidth: "100%",
                      },
                      image: {},
                    },
                    onPress: () => {
                      setBtnSelected([menu.page]);
                      if (menu.children?.length > 0) setMenuOpen(true);
                      if (menu.onClick)
                        try {
                          menu.onClick(menu.page);
                        } catch (e) {}
                      else {
                        GoPage(menu.page);
                      }
                    },
                  }),
                  //subMenu
                  {
                    component: "container",
                    style: {
                      display:
                        btnSelected.includes(menu.page) &&
                        menuOpen === true &&
                        menu.children?.length > 0
                          ? "flex"
                          : "none",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: 4,
                      paddingLeft: "14%",
                      gap: 8,
                      zIndex: 9,
                    },
                    children: menu.children?.map((subMenu) => {
                      return Icon({
                        size: "74%",
                        title: subMenu?.title,
                        src: subMenu.image?.src,
                        style: {
                          iconBox: {
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            maxHeight: "4vh",
                            borderRadius: 4,
                            transition: "transform 0.3s ease-in-out",
                            justifyContent:
                              menuOpen === true ? "flex-start" : "center",
                            transition: "background-color 0.5s ease-in-out",
                            background: btnSelected.includes(subMenu.page)
                              ? "rgba(228, 238, 255, 1)"
                              : "transparent",
                          },
                          title: {
                            display: menuOpen === true ? "flex" : "none",
                            fontWeight: "400",
                            paddingLeft: 4,
                            color: btnSelected.includes(subMenu.page)
                              ? "rgba(17, 24, 39, 1)"
                              : "gray",
                            textAlign: "flex-start",
                          },
                          image: {},
                        },
                        onPress: () => {
                          setBtnSelected([menu.page, subMenu.page]);
                          if (subMenu.onClick)
                            try {
                              subMenu.onClick(subMenu.page);
                            } catch (e) {}
                          else {
                            GoPage(subMenu.page);
                          }
                        },
                      });
                    }),
                  },
                ],
              };
            }),
          },

          //logout/version
          {
            component: "container",
            style: {
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 6,
              paddingLeft: menuOpen === true ? "0.66vw" : 6,
              ...props?.style?.logoutBox,
            },
            children: [
              Icon({
                src: Logout,
                size: "72%",
                title: "Sair",
                style: {
                  iconBox: {
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRadius: 4,
                    height: "4vh",
                    width: menuOpen === true ? "100%" : "82%",
                    justifyContent: menuOpen === true ? "flex-start" : "center",
                    padding: 2,
                    transition: "background-color 0.5s ease-in-out",
                  },
                  title: {
                    fontSize: "1.42vh",
                    display: menuOpen === true ? "flex" : "none",
                    fontWeight: "400",
                    textAlign: "flex-start",
                    color: "red",
                  },
                  image: {},
                },
                onPress: () => {
                  PopupShow({
                    title: "Deseja mesmo sair?",
                    confirmText: "sim",
                    cancelText: "não",
                    onConfirm: () => {
                      localStorage.clear();
                      sessionStorage.clear();
                      window.location.reload();
                    },
                  });
                },
              }),
              // {
              //   component: "text",
              //   text: "V:" + process.env.REACT_APP_VERSION,
              //   style: {
              //     display: "flex",
              //     padding: 2,
              //     fontSize: 12,
              //     marginTop: 14,
              //     alignItems: "center",
              //     textAlign: "center",
              //     justifyContent: "center",
              //     fontFamily: Theme.fontFamilies.secondary,
              //   },
              // },
            ],
          },
        ],
      },
    ],
  };
};
