//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//import global
import AddView from "components/web/view/add";

//images
// import OctalLogo from "./midia/images/8D/logo.svg";
const PageID = "ClientsAdd";

export default ({ ContentShow }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();

  const [typeProvider, setTypeProvider] = useState(false);

  const [employeeNameProvider, setEmployeeNameProvider] = useState("");

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
      maxWidth: "100%",
      minWidth: "100%",
    },
    title: {
      fontFamily: "Poppins",
    },
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
      fontFamily: "Poppins",
    },
  };

  return {
    render: (
      <Fade>
        {Render({
          component: "container",
          style: {
            display: ContentShow === PageID ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            width: "100%",
          },
          children: [
            AddView({
              direction: "row",
              title: "Novo cliente",
              form: {
                onSave: api.provider.add,
                // func: (data, info, clear, goBack) => {
                //   const error = data.error;
                //   if (error) {
                //     PopupShow({
                //       title: "Campos não preenchidos",
                //       description: "Por favor, revise e complete os dados",
                //       style: {
                //         close: {
                //           display: "none",
                //         },
                //       },
                //       cancelText: "",
                //       confirmText: "Ok",
                //       onCancel: async () => {},
                //       onConfirm: async () => {},
                //     });
                //   }
                //   const status = data.status;
                //   if (status === false) {
                //     if (status === false)
                //       PopupShow({
                //         title: "Este CNPJ já está cadastrado ",
                //         description: "Você deseja continuar?",
                //         cancelText: "Não",
                //         confirmText: "Sim",
                //         style: {
                //           close: {
                //             display: "none",
                //           },
                //         },
                //         onCancel: async () => {
                //           clear();
                //           setEmployeeNameProvider("");
                //         },
                //         onConfirm: async () => {
                //           const result = await api.provider.add({
                //             force: true,
                //             ...info,
                //           });
                //           if (result?.data?._id) {
                //             clear();
                //             setEmployeeNameProvider("");
                //             PopupShow({
                //               title:
                //                 (result.data.nickname || result.data.name) +
                //                 ", foi adicionado!",
                //               description: "Deseja continuar",
                //               cancelText: "Não",
                //               confirmText: "Sim",
                //               onCancel: async () => {
                //                 clear();
                //                 goBack();
                //               },
                //             });
                //           }
                //         },
                //       });
                //   } else {
                //   }
                // },
                style: {
                  main: {
                    flexWrap: "wrap",
                    overflow: "auto",
                    maxWidth: "32%",
                    height: "auto",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: 18,
                  },
                  box: {
                    1: {},
                  },
                  btn: {
                    cancel: {},
                    save: {
                      backgroundColor: "#212121",
                      height: 32,
                    },
                    style: {
                      maxWidth: "32%",
                    },
                  },
                },
                box: {
                  //Informações pessoais
                  1: {
                    style: {
                      // padding: "2%",
                      flex: 1,
                      minWidth: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 4,
                      gap: 12,
                    },
                    input: [
                      //Nome
                      {
                        id: "nickname",
                        label: "Nome Completo",
                        type: "input",
                        style: inputStyle,
                        required: true,
                        // check: { min: 5 },
                      },
                      //cnpj
                      {
                        type: "div",
                        style: {
                          display: "flex",
                          alignItems: "flex-end",
                        },
                        children: [
                          // Switch
                          {
                            type: "switch",
                            label: "Pessoa física",
                            style: {
                              position: "absolute",
                              marginTop: -18,
                              display: "flex",
                              fontFamily: "Poppins",
                              fontSize: 12,
                              alignItems: "center",
                            },
                            onChange: async (v) => {
                              // console.log("eeee", v);
                              setTypeProvider(v);
                            },
                            checked: true,
                            value: typeProvider,
                          },
                          //input
                          {
                            id: "code",
                            keyid: "code",
                            label: typeProvider ? "CPF" : "CNPJ",
                            type: "input",
                            style: inputStyle,
                            check: { type: "string" },
                            mask: typeProvider
                              ? "999.999.999-99"
                              : "99.999.999/9999-99",
                            set: async (value) => {
                              if (typeProvider === false) {
                                if (value.length >= 14) {
                                  const { data } = await api.utils.clientCode({
                                    query: { code: value },
                                  });
                                  if (data?.data?.razao_social)
                                    setEmployeeNameProvider(
                                      data.data.razao_social
                                    );
                                  else setEmployeeNameProvider("");
                                } else setEmployeeNameProvider("");
                              } else setEmployeeNameProvider("");
                            },
                            required: true,
                          },
                        ],
                      },
                      //email
                      {
                        id: "email",
                        label: "Email",
                        type: "input",
                        style: inputStyle,
                        required: true,
                        // check: { min: 5 },
                      },
                      //telefone
                      {
                        id: "phone",
                        label: "Telefone",
                        mask: "(99) 99999-9999",
                        type: "input",
                        style: inputStyle,
                        required: true,
                        // check: { min: 5 },
                      },
                      //Tipo de cargo
                      {
                        id: "register_type",
                        //keyid: "register_type",
                        label: "Cargo",
                        type: "select",
                        option: [
                          {
                            _id: 1,
                            name: "Front-end",
                          },
                          {
                            _id: 2,
                            name: "Back-end",
                          },
                          {
                            _id: 3,
                            name: "Supervisor",
                          },
                          {
                            _id: 4,
                            name: "Design",
                          },
                        ],
                        style: {
                          ...inputStyle,
                          title: {
                            fontSize: 12,
                            fontFamily: "Poppins",
                            color: "#3C3C3C",
                          },
                          marginTop: 4,
                        },
                        required: true,
                      },
                      //ID
                      {
                        id: "_id",
                        label: "",
                        type: "input",
                        style: {
                          display: "none",
                        },
                        required: true,
                      },
                      //Type
                      {
                        id: "type",
                        label: "",
                        value: "provider",
                        type: "input",
                        style: {
                          display: "none",
                        },
                        required: true,
                      },
                    ],
                  },
                  2: {
                    style: {
                      flex: 1,
                      minWidth: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 4,
                      gap: 12,
                    },
                    input: [
                      //nameCNPJ
                      {
                        id: "name",
                        value: employeeNameProvider,
                      },
                    ],
                  },
                },
              },
              showPopup: false,
            }),
          ],
        })}
      </Fade>
    ),
  };
};
