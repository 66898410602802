//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//import global
import Input from "components/web/input";
import Select from "components/web/select";
import Button from "components/web/button";
import AnimationText from "components/web/animationText";
import CardInfo from "components/web/cardInfo";
import Navbar from "components/web/navbar";

//images
// import OctalLogo from "./midia/images/8D/logo.svg";

const PageID = "Home";

export default ({ ContentShow }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();
  useEffect(async () => {
    console.log(ContentShow);
  }, [ContentShow]);
  return {
    render: (
      <Fade>
        {Render({
          component: "container",
          style: {
            display: ContentShow === PageID ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            width: "100%",
          },
        })}
      </Fade>
    ),
  };
};
