import Theme from "theme";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import Cel from "./cel";

export default (props) => {
  return {
    component: "container",
    style: {
      flex: 1,
      display: "flex",
      minHeight: "100%",
      minWidth: "100%",
      alignItems: "center",
      justifyContent: "center",
      ...props.getRowStyle(props.id),
    },
    // Row style
    children: [Cel(props)],
    onClick: async (item) => {
      // api.download.sheetModel("modelo_cadastro.xlsx");
      try {
        props.item.onClick(item);
      } catch (e) {
        console.log("Error onClick Table Row", props, e);
      }
    },
  };
};
