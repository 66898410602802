import { Render } from "@8_dev/sjson-web";

// import { Render } from "render";

import { useEffect, useState } from "react";
import { ShowPage } from "context/showPage";

//components
import Navbar from "components/web/navbar";

//images e icons
import HomeIcon from "assets/images/octal/menu/home.svg";
import MembersIcon from "assets/images/octal/menu/members.svg";
import IconCurriculum from "assets/images/octal/menu/curriculum.svg";
import ClientsIcon from "assets/images/octal/menu/clients.svg";

//pages
import Home from "../home";
import Members from "../members";
import newPeoples from "../newPeoples";
import Clients from "../clients";

export default () => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();

  const [ContentShow, setContentShow] = useState("Home");

  const ContentParam = {
    GoContent: setContentShow,
    ContentShow,
  };

  useEffect(async () => {}, []);

  // let Pages = [];
  const Nav = {
    component: "container",
    style: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    children: [
      Navbar({
        style: {
          boxMain: {
            maxHeight: "98%",
            height: "100%",
            borderRadius: 0,
            borderRight: "1px solid gray",
            boxShadow: "",
          },
          menu: {
            marginTop: 12,
          },
        },
        item: [
          // Home
          {
            // title: "Início",
            page: "Home",
            image: {
              src: HomeIcon,
            },
            onClick: (page) => {
              setContentShow(page);
            },
          },
          // Pedidos
          {
            // title: "Pedidos",
            page: "List",
            image: {
              src: MembersIcon,
            },
            onClick: (page) => {
              setContentShow(page);
            },
          },
          // Lista de curriculos
          {
            // title: "Pedidos",
            page: "NewPeoples",
            image: {
              src: IconCurriculum,
              size: "100%",
            },
            onClick: (page) => {
              setContentShow(page);
            },
          },
          // Lista de clientes
          {
            // title: "Pedidos",
            page: "ClientsList",
            style: {
              iconBox: {
                marginLeft: 4,
              },
            },
            image: {
              src: ClientsIcon,
              size: "100%",
            },
            onClick: (page) => {
              setContentShow(page);
            },
          },
        ],
      }),
    ],
  };

  const PageStyle = {
    display: "flex",
    flexDirection: "row",
    width: "100vw",
    minWidth: "100vw",
    maxWidth: "100vw",
    height: "100vh",
    maxHeight: "100vh",
    minHeight: "100vh",
    overflow: "hidden",
    alignItems: "center",
  };

  const Pages = [
    Nav,
    ...Home(ContentParam),
    ...Members(ContentParam),
    ...newPeoples(ContentParam),
    ...Clients(ContentParam),
  ];
  return {
    component: "container",
    style: PageStyle,
    children: Pages,
  };
};
