// criar state para modo escuro
// criar state para tamanho da fonte
// criar state para tamanho da tela

const opacity = {
  0: "09",
  10: "0.1",
  20: "0.2",
  30: "0.3",
  40: "0.4",
  50: "0.5",
  60: "0.6",
  70: "0.7",
  80: "0.8",
  90: "0.9",
  100: "1",
};
const colors = {
  primary: "#FF5876",
  secondary: "#1546A3",
  pink: {
    light: "#FEA6B6",
    dark: "#EF1959",
    background: "#F7F7FC",
    text: "#EF1959",
    full: "#FEA6B6",
  },
  black: {
    light: "#F7F7FC",
    dark: "#3C3C3C",
    background: "#F7F7FC",
    text: "#3C3C3C",
    full: "#000",
  },
  blue: {
    light: "#B3D9F8",
    number: "#58AFFF",
    dark: "#1546A3",
    background: "#F7F7FC",
  },
  white: {
    background: "#F7F7FC",
    text: "#fff",
    full: "#fff",
  },
  gray: {
    light: "#F7F7FC",
    dark: "#3C3C3C",
    medium: "rgba(100, 100, 100, 0.2)",
    background: "rgba(100, 100, 100, 0.2)",
    text: "rgba(100, 100, 100, 0.8)",
    full: "#bfbfbf",
  },
  green: {
    light: "#19583E",
    dark: "#0A422B",
    background: "#F7F7FC",
    text: "#00A660",
    full: "#00A660",
    yes: "#00A650",
  },
  red: {
    light: "#FF5876",
    dark: "#EF1959",
    background: "#F7F7FC",
    text: "#EF1959",
    full: "#FF5876",
    no: "#FF5876",
  },
  yellow: {
    light: "#FFD500",
    dark: "#FFD500",
    background: "#F7F7FC",
    text: "#FFD500",
    full: "#FFD500",
  },
  orange: {
    light: "#FFD500",
    dark: "#FFD500",
    background: "#F7F7FC",
    text: "#FFD500",
    full: "#FFD500",
  },
  purple: {
    light: "#FFD500",
    dark: "#FFD500",
    background: "#F7F7FC",
    text: "#FFD500",
    full: "#FFD500",
  },
  brown: {
    light: "#FFD500",
    dark: "#FFD500",
    background: "#F7F7FC",
    text: "#FFD500",
    full: "#FFD500",
  },
  cyan: {
    light: "#FFD500",
    dark: "#FFD500",
    background: "#F7F7FC",
    text: "#FFD500",
    full: "#FFD500",
  },
  transparent: {
    background: "rgba(100,100,100,0.15)",
    text: "transparent",
    full: "transparent",
  },
};
const elevations = {
  1: "0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)",
  2: "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
  3: "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)",
  4: "0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)",
  5: "0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16)",
  6: "0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)",
};
// criar melhores variações de fonte em conformidade com melhores site da apple e outros
const fontFamilies = {
  primary: "Poppins",
  secondary: "Poppins",
  body: "Poppins",
  label: "Poppins", // Mantendo Roboto para consistência
};
const fontSizes = {
  title: "0.88vw",
  button: "0.64vw",
  subTitle: "0.68vw",
  tiny: "0.36vw",
  small: "0.45vw",
  normal: "1vh",
  focus: "1.2vh",
  capitalized: "1.4vh",
  blockquotes: "0.76vw",
  body14: "0.44vw",
  body16: "0.64vw",
  lead: "0.40vw",
  big: "1vw",
  huge: "1.4vw",
  col: "1.4vh",
  row: "0.82vw",
  form: {
    title: "1.8vh",
    input: "1vh",
  },
  hugeTitle: 24,
  bigTitle: 16,
};
const padding = {
  small: 5,
  normal: 10,
  medium: 15,
  big: 35,
};
const margin = {
  small: 5,
  normal: 10,
  medium: 15,
  big: 35,
};
const borderRadius = {
  small: 4,
  normal: 8,
  medium: 16,
  big: 24,
  huge: 32,
  circle: 1000,
};
const borderWidth = {
  small: 1,
  normal: 2,
  medium: 3,
  big: 5,
};
const borderStyle = {
  solid: "solid",
  dotted: "dotted",
  dashed: "dashed",
};
const shadow = {
  small: {
    boxShadow: "1px 1px 8px " + colors.gray.medium,
  },
  normal: {
    boxShadow: "1px 1px 8px " + colors.gray.medium,
  },
  medium: {
    boxShadow: "1px 1px 8px " + colors.gray.medium,
  },
  big: {
    boxShadow: "1px 1px 8px " + colors.gray.medium,
  },
};
const borders = {
  small: {
    borderWidth: borderWidth.small,
    borderStyle: borderStyle.solid,
    borderColor: colors.gray.medium,
    borderRadius: borderRadius.medium,
  },
  normal: {
    borderWidth: borderWidth.normal,
    borderStyle: borderStyle.solid,
    borderColor: colors.gray.medium,
    borderRadius: borderRadius.normal,
  },
  medium: {
    borderWidth: borderWidth.medium,
    borderStyle: borderStyle.solid,
    borderColor: colors.gray.dark,
    borderRadius: borderRadius.medium,
  },
  big: {
    borderWidth: borderWidth.big,
    borderStyle: borderStyle.solid,
    borderColor: colors.gray.dark,
    borderRadius: borderRadius.big,
  },
};
const box = {
  default: {
    backgroundColor: colors.transparent.full,
    // border: borders.normal,
    borderRadius: borderRadius.normal,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    // padding: padding.normal,
    // margin: margin.normal,
    // ...elevations.normal,
  },
  form: {
    backgroundColor: colors.transparent.full,
    borderRadius: borderRadius.small,
    ...borders.normal,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    // padding: padding.normal,
    // margin: margin.normal,
    // ...elevations.normal,
  },
  container: (opt) => {
    return {
      width: "100%",
      // backgroundColor: colors.white.full,
      ...shadow.normal,
      ...borders.normal,
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "center",
      ...opt.style,
    };
  },

  login: {
    minWidth: "64.6vw",
    maxWidth: "64.6vw",
    minHeight: "47.5vh",
    maxHeight: "47.5vh",
    backgroundColor: "rgba(255,255,255,0.64)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: "4.75vh",
    // padding: "8vh",
    // paddingTop: "12.5vh",
    borderColor: colors.secondary,
    borderWidth: borderWidth.small,
    borderStyle: borderStyle.solid,
    borderRadius: borderRadius.medium,
  },

  indicator: {
    main: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: 16,
      maxWidth: 300,
      margin: 8,
      padding: 8,
    },
    title: {
      color: colors.blue.text,
      fontSize: fontSizes.capitalized,
      fontWeight: 500,
      fontFamily: fontFamilies.primary,
      marginBottom: margin.small,
    },
    value: {
      color: colors.blue.number,
      fontSize: fontSizes.huge,
      fontFamily: fontFamilies.primary,
    },
  },

  home: {
    // HomeBox card
    card: {
      main: {
        marginLeft: "2.5%",
        marginRight: "2.5%",
        width: "45%",
        display: "flex",
        flexDirection: "column",
        borderRadius: borderRadius.normal,
        alignItems: "center",
        justifyContent: "center",
        ...shadow.small,
      },
      top: {
        main: {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 8,
          marginTop: 8,
        },
        title: {
          flex: 1,
          marginLeft: "4%",
          fontFamily: fontFamilies.primary,
          fontSize: fontSizes.big,
          color: colors.black.text,
        },
        icon: {},
        count: {
          flex: 1,
          marginLeft: "8%",
          fontFamily: fontFamilies.primary,
          fontSize: fontSizes.normal,
          color: colors.black.text,
        },
      },
      action: {
        main: {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: padding.normal,
          marginTop: margin.normal,
        },

        seeAll: {
          main: {
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: colors.blue.light,
            padding: padding.normal,
            borderRadius: borderRadius.normal,
          },
          title: {
            fontFamily: fontFamilies.primary,
            fontSize: fontSizes.normal,
            color: colors.pink.text,
          },
        },

        label: {
          title: {
            fontFamily: fontFamilies.primary,
            fontSize: fontSizes.normal,
            color: colors.white.full,
          },
        },

        buttom: {
          cursor: "pointer",
          fontFamily: fontFamilies.primary,
          textAlign: "center",
          fontFamilie: fontFamilies.primary,
          color: colors.white.full,
          fontSize: fontSizes.normal,
          backgroundColor: colors.pink.dark,
          borderRadius: borderRadius.normal,
          padding: padding.normal,
          marginRight: margin.normal,
        },
      },
    },
  },
};
const button = {
  default: {
    cursor: "pointer",
    backgroundColor: colors.white.background,
    border: borders.small,
    borderRadius: borderRadius.small,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: padding.small,
    margin: margin.small,
  },
  login: {
    cursor: "pointer",
    backgroundColor: colors.primary,
    border: borders.small,
    borderRadius: borderRadius.small,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: padding.small,
    paddingLeft: padding.big,
    paddingRight: padding.big,
    margin: margin.small,
    color: "rgba(0,0,0,1)",
    fontSize: "0.8vh",
    fontFamily: "Poppins",
    maxHeight: 30,
    // maxWidth: 90,
  },
  action: {
    backgroundColor: colors.primary,
    padding: 8,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "1px 1px 8px #bfbfbf",
    cursor: "pointer",
  },
};
const text = {
  default: {
    fontSize: fontSizes.normal,
    color: colors.black,
    fontWeight: "bold",
    fontFamily: fontFamilies.primary,
  },
  button: {
    fontSize: fontSizes.huge,
    color: colors.white,
    fontWeight: "bold",
    fontFamily: fontFamilies.primary,
  },
  buttonHover: {
    fontSize: fontSizes.huge,
    color: colors.white,
    fontWeight: "bold",
    fontFamily: fontFamilies.primary,
  },
};

const icon = (opt) => {
  return {
    default: {
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      aspectRatio: 1,
    },
    title: {
      fontFamily: fontFamilies.primary,
      fontSize: opt === 0 ? fontSizes.small : fontSizes.body16,
      color: colors.black.text,
    },
  };
};

export default {
  text: {
    bigTitle: {
      fontFamily: fontFamilies.primary,
      fontSize: fontSizes.hugeTitle,
      color: colors.black.text,
      fontWeight: "600",
    },
    title: {
      fontFamily: fontFamilies.primary,
      fontSize: fontSizes.bigTitle,
      color: colors.black.text,
      fontWeight: "400",
    },
    login: {
      fontFamily: fontFamilies.label,
      fontSize: fontSizes.bigTitle,
      color: colors.black.text,
      fontWeight: "600",
      padding: 4,
    },
    label: {
      fontFamily: fontFamilies.label,
      fontSize: fontSizes.capitalized,
      color: colors.black.text,
      fontWeight: "400",
    },
    default: {
      fontFamily: fontFamilies.label,
      fontSize: fontSizes.body14,
      color: colors.black.text,
    },
    button: {
      fontFamily: fontFamilies.primary,
      fontSize: fontSizes.huge,
      color: colors.white.full,
      cursor: "pointer",
    },
    input: {
      title: {
        fontFamily: fontFamilies.primary,
        fontSize: fontSizes.capitalized,
        color: colors.black.text,
        fontWeight: "400",
      },
      value: {
        fontFamily: fontFamilies.primary,
        fontSize: fontSizes.subTitle,
        color: colors.black.text,
      },
    },
    notify: {
      fontFamily: fontFamilies.primary,
      fontSize: fontSizes.body16,
      color: colors.black.text,
    },
  },
  icon,
  colors,
  fontFamilies,
  fontSizes,
  padding,
  margin,
  elevations,
  opacity,
  shadow,
  borders,
  borderRadius,
  box,
  button,
};
