import Theme from "theme";
import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default (props) => {
  const [component, setComponent] = useState({
    value: "",
    focus: false,
    error: false,
    ...props,
  });

  const [inputValue, setInputValue] = useState(false);

  return {
    component: "container",
    style: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: 2,
      gap: 2,
      ...props.style,
    },
    onClick: () => {
      setComponent({ ...component, focus: true });
    },
    children: [
      {
        component: "text",
        text: props.title,
      },
      {
        render: (
          <Switch
            checked={props.checked}
            value={props.value}
            onChange={async (value) => {
              setInputValue(!inputValue);
              if (props.onChange)
                try {
                  props.onChange(!inputValue);
                } catch (e) {
                  console.log("switch error", e);
                }
            }}
            size={component.size || "medium"}
            // mudar cor se providetype 2
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: Theme.colors.primary,
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track.MuiSwitch-track.MuiSwitch-track":
                {
                  backgroundColor: Theme.colors.primary,
                },
            }}
          />
        ),
      },
    ],
  };
};
